@import 'colors', 'fonts';

.button{
  background-color: $orange;
  border:none;
  color: $white;
  @include sansserif(bold);
  cursor:pointer;
  display:inline-block;
  text-decoration:none;
  display:inline-block;
  text-transform: uppercase;
  position: relative;
  &:hover{
    background: $darkgreen;
  }
  &:active{
    background: $lightgreen;
  }
  &.XL{
    padding: 26px 30px 20px 30px;
    font-size: 2.375rem;
    line-height:120%;
    @media only screen and (max-width: $breakXS) {
      font-size: 28px;
      padding: 16px 30px 10px 30px;
    }
  }
  &.L{
    padding: 0 20px;
    font-size: 28px;
    line-height:64px;
    height:60px;
    @media only screen and (max-width: $breakXS) {
      font-size: 20px;
    }
    &:after{
      content: '';
      border-top: solid 30px transparent;
      border-right: solid 30px transparent;
      border-bottom: solid 30px transparent;
      border-left: solid 30px $orange;
      width:0px;
      height:0px;
      font-size:0px;
      position: absolute;
      top:0px;
      right: -60px;
    }
    &:hover{
      color: $white;
      &:after{
        border-top: solid 30px transparent;
        border-right: solid 30px transparent;
        border-bottom: solid 30px transparent;
        border-left: solid 30px $darkgreen;
      }
    }
    &:active{
      &:after{
        border-top: solid 30px transparent;
        border-right: solid 30px transparent;
        border-bottom: solid 30px transparent;
        border-left: solid 30px $lightgreen;
      }
    }
  }
  &.M{
    padding: 11px 10px 8px 10px;
    font-size: 18px;
    line-height:120%;
  }
}
