
#kennstdu{
  position: relative;
  .inner{
    position:absolute;
    width: 100%;
    left: 50%;
    top:-75px;
    transform: translate(-50%, 0%);
    h2{
      width: 100%;
      margin:0;
      background: $orange;
      color: $white;
      font-weight: 700;
      font-size: 60px;
      line-height: 120%;
      padding: 43px 0 35px 0;
      text-transform:uppercase;
    }
  }    
}
