
#keinmitglied{
  background: $orange20;
  .inner{
    padding: 80px 0;
    text-align:center;
    h2{
      margin:0 0 20px 0;
      @include sansserif(bold);
      color: $black;
      font-size: 40px;
      @media only screen and (max-width: $breakXS) {
        font-size: 30px;
      }
    }
    p{
      max-width: 760px;
      margin: 0 auto;
    }
    .button{
      margin-top: 50px;
    }
  }
}
