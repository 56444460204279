
#footer{
  padding:0!important;
  &:hover{
    .inner{
      opacity: 1;
    }
  }
  .inner{
    padding-bottom: 10px;
    opacity: 0.5;
    a{
      // color: $darkgray;
      border-right: solid 1px $darkgray;
      padding: 2px 10px 0 10px;
      &:last-child{
        border-right: none;
      }
    }
  }
  background: $orange20;
  @include sansserif(normal);
  text-transform: uppercase;
  text-align: center;
}
