@import '_fonts', '_breakpoints', '_colors', 'sections/_head', 'sections/_kennstdu', 'sections/_info', 'sections/_keinmitglied', 'sections/_footer', 'components/_buttons';

body, html{
  font-size:16px;
}
h1, h2, h3, h4{
  line-height:120%;
}
h1{
  @include sansserif(bold);
  font-size: 7.5rem;
  margin-bottom: 30px;
  @media only screen and (max-width: $breakL) {
    font-size: 100px;
  }
  @media only screen and (max-width: $breakM) {
    font-size: 70px;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: $breakXS) {
    font-size: 44px;
    margin-bottom: 10px;
  }
}
h2{
  @include sansserif(normal);
  font-size: 1.875rem;
  color: $orange;
  text-align:center;
  margin: 80px 0 40px 0;
  @media only screen and (max-width: $breakS) {
      margin: 60px 0 20px 0;
  }
}
h3{
  color: $black;
  @include sansserif(normal);
  font-size: 1.5625rem;
}
h4{
  color: $darkgreen;
  @include sansserif(normal);
  font-size: 1.3rem;
}

p{
  font-size: 25px;
  @include serif(normal);
  color: $black;
  margin:0 0 40px 0;
  line-height:140%;
  @media only screen and (max-width: $breakXS) {
    font-size: 20px;
  }
  &.big{
    font-size: 30px;
    @media only screen and (max-width: $breakXS) {
      font-size: 25px;
    }
  }
}

section{
  width:100%;
  &:first-child{
    .inner{
      border-bottom:none;
    }
  }
  .inner{
    margin: 0 auto;
    padding-bottom: 80px;
    max-width: $maxWidthSections;
    @media only screen and (max-width: calc($breakXXL + 100px)) {
      margin: 0 50px;
    }
    @media only screen and (max-width: $breakS) {
      margin: 0 30px;
      padding-bottom: 60px;
    }
  }
}

.clearBoth{
  clear: both;
}

a{
  color: $orange;
  text-decoration: none;
  &:hover{
    color: $darkgreen;
  }
}