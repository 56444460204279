$orange: #ff6d00;
$orange20: #FFE2CC;
$orange10: #FFF0E5;

$darkgray: #666666;
$lightgray: #eee;

$darkgreen: #00504b;
$darkgreen30: #B2CAC8;
$darkgreen20: #CCDCDB;
$darkgreen10: #E5EDEC;

$freshgreen: #c7d300;

// $lightgreen: #048174; Originalfarbe
$lightgreen: #048075;
$lightgreen20: #CCE6E3;

$lightblue: #b9e0de;

$purple: #5c004e;

$yellow: #f0dc00;

$white: #fff;

$black: #000;
