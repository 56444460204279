@import 'colors', 'fonts', 'breakpoints';

@keyframes person {
  0% {bottom: -100%}
  100% {bottom: 0%}
}

#head{
  // height: calc(100vH - 75px);
  min-height: calc(100vH - 75px);
  position: relative;
  color: $black;
  background-image: url('../img/bg_head.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size:cover;
  align-items: center;
  display:flex;
  @media only screen and (max-width: $breakXL) {
  }
  @media only screen and (max-width: $breakL) {
  }
  @media only screen and (max-width: $breakM) {
    
  }
  @media only screen and (max-width: $breakS) {
    background-position: right -300px center;
  }
  @media only screen and (max-width: $breakXS) {
    // height: 100vh;
    // height: -webkit-fill-available;
    // height: -moz-available;
  }
  @media only screen and (max-width: $breakXS) {
  }
  @media only screen and (max-height: 730px) {
  }
  .inner{
    height:calc(100% - 75px);
    padding:0;
    max-width: $maxWidthHead;
    align-items: center;
    display:flex;
    @media only screen and (max-width: $breakXL) {
      margin: 0 50px;
    }
    @media only screen and (max-width: $breakM) {
      margin: 0 30px;
    }
    @media only screen and (max-width: $breakS) {
      padding-top:30px;
      padding-bottom: 100px;
    }
    .cols{
      display: flex;
      height: 100%;
      align-items: center;
      > div {
        &:first-child{
          padding: 0 20px 0 0;
          img{
            margin-top: 2px;
          }
        }
        &:last-child{
          padding: 0 0px 0 20px;
        }
      }
      @media only screen and (max-width: $breakL) {
        > div{
          &:first-child{
            img{
              width: 250px;
              margin-top: 15px;
            }
          }
        }
      }
      @media only screen and (max-width: $breakM) {
        > div{
          &:first-child{
            img{
              width: 200px;
              margin-top: 30px;
            }
          }
        }
      }
      @media only screen and (max-width: $breakS) {
       display:block;
       height:auto!important;
       > div{
        padding: 0!important;
        &:first-child{
          padding: 0;
          text-align:center;
          img{
            width: 200px;
            margin-top: 40px;
          }
        }
      }
      }
    }
    h1{
      color: $orange;
      font-size:70px;
      font-weight:700;
      margin: 70px 0 0 0;
      text-transform:uppercase;
      @media only screen and (max-width: $breakL) {
        font-size:60px;
      }
      @media only screen and (max-width: $breakM) {
        font-size:50px;
      }
      @media only screen and (max-width: $breakS) {
        margin: 50px 40px 30px 40px;
        text-align:center;
      }
      @media only screen and (max-width: $breakXS) {
        margin: 50px 0 30px 0;
        text-align:center;
        padding:0!important;
        font-size:30px;
      }
    }
    ul{
      list-style-type: none;
      font-size:25px;
      line-height:140%;
      font-family: SuperGrotesk;
      padding:0;
      @media only screen and (max-width: $breakL) {
        font-size:22px;
      }
      @media only screen and (max-width: $breakS) {
        margin: 0 40px;
        font-size:20px;
      }
      @media only screen and (max-width: $breakXS) {
        margin: 0 auto;
      }
      li{
        position: relative;
        padding: 0 0 0 25px;
        margin: 0 0 20px 0;
        @media only screen and (max-width: $breakL) {
          margin: 0 0 15px 0;
        }
        &:before{
          content: ' ';
          width: 15px;
          height: 15px;
          display: block;
          background: $lightgreen;
          position:absolute;
          left: 0px;
          top: 8px;
          @media only screen and (max-width: $breakL) {
            top: 6px;
          }
        }
      }
    }
  }
  .kennstdu{
    position:absolute;
    width: 100%;
    left: 50%;
    bottom:-75px;
    transform: translate(-50%, 0%);
    max-width: $maxWidthSections;
    @media only screen and (max-width: $breakL) {
      width: calc(100% - 80px);
    }
    @media only screen and (max-width: $breakM) {
      width: calc(100% - 60px);
      bottom:-65px;
    }
    @media only screen and (max-width: $breakXS) {
      width: 100%;
      bottom:-65px;
    }
    h2{
      margin:0;
      background: $orange;
      color: $white;
      font-weight: 700;
      font-size: 60px;
      line-height: 120%;
      padding: 43px 0 35px 0;
      text-transform:uppercase;
      @media only screen and (max-width: $breakM) {
        font-size: 50px!important;
        padding: 43px 0 35px 0;
        font-size: 50px;
      }
      @media only screen and (max-width: $breakXS) {
        font-size: 30px!important;
      }
    }
    .person{
      overflow:hidden;
      position:absolute;
      bottom:0px;
      right:20px;
      width: 276px;
      height: 241px;
      @media only screen and (max-width: $breakL) {
        right:-40px;
      }
      @media only screen and (max-width: $breakS) {
        display:none;
      }
      &.thinking{
        .person_1{
          display:block;
        }
        .person_2{
          display:none;
        }
      }
      .person_1{
        display:none;
      }
      .person_2{
        display:block;
      }
      .person_1{
        height: 241px;
        position:absolute;
        bottom:-100%;
        right:0;
        animation-name: person;
        animation-duration: 0.5s;
        animation-delay: 1s;
        animation-iteration-count:1;
        animation-fill-mode:forwards; 
      }
      .person_2{
        height: 241px;
        position:absolute;
        bottom:0%;
        right:0;
      }
    }
  }
}
