
#info{
  .inner{
    padding: 75px 0 40px 0;
    h2{
      color: $black;
      font-size: 40px;
      font-weight: 700;
      margin: 80px 0 30px 0;
      span{
        color: $orange;
      }
      @media only screen and (max-width: $breakS) {
        font-size: 30px;
      }
    }
    p.big{
      text-align: center;
      max-width: 760px;
      margin: 0 auto 20px auto;
    }
  } 
  .cols{
    display: flex;
    flex-wrap: wrap;
    @media only screen and (max-width: calc($breakXXL + 100px)) {
      margin-bottom: 60px;
    }
    @media only screen and (max-width: $breakM) {
      display: block;
    }
    > div{
      position: relative;
      background: $lightgreen20;
      margin: 75px 20px 80px 20px;
      width: calc(25% - 30px);
      padding: 0;
      transition: all 0.2s;
      @media only screen and (max-width: calc($breakXXL + 100px)) {
        width: calc(50% - 20px);
        margin-bottom: 30px;
      }
      @media only screen and (max-width: $breakM) {
        width: 100%;
        margin: 90px 0 100px 0;
      }
      a{
        color: $lightgreen;
        text-decoration: underline;
        &:hover{
          text-decoration: none;
        }
      }
      &:hover{
        transform: scale(105%);
      }
      &:before{
        content: ' ';
        display:block;
        background: $white;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-position: center center;
        background-size: 100px 100px;
        background-repeat: no-repeat;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &.versicherung{
        &:before{
          background-image: url('/img/versicherung.svg');
        }
      }
      &.beratung{
        &:before{
          background-image: url('/img/beratung.svg');
        }
      }
      &.fonds{
        &:before{
          background-image: url('/img/fonds.svg');
        }
      }
      &.lvm{
        &:before{
          background-image: url('/img/rechtsschutz.svg');
        }
      }
      &:first-child{
        margin-left: 0;
      }
      &:last-child{
        margin-right: 0;
      }
      @media only screen and (max-width: calc($breakXXL + 100px)) {
        &:nth-child(even){
          margin-left: 20px;
          margin-right: 0px;
        }
        &:nth-child(odd){
          margin-right: 20px;
          margin-left: 0px;
        }
      }
      @media only screen and (max-width: $breakM) {
        &:nth-child(even){
          margin-left: 0px;
          margin-right: 0px;
        }
        &:nth-child(odd){
          margin-right: 0px;
          margin-left: 0px;
        }
      }
      .top{
        display:table;
        margin: 75px 20px 0 20px;
        @media only screen and (max-width: $breakM) {
          display:block;
          height:auto!important;
        }
        h3{
          padding: 0;
          text-align:center;
          font-size: 25px;
          margin:0;
          vertical-align: middle;
          display:table-cell;
          @media only screen and (max-width: $breakM) {
            padding: 105px 0 30px 0;
            text-align:center;
            display:block;
            width: 100%;
          }
          @media only screen and (max-width: $breakXS) {
            font-size: 20px;
          }
        }
      }
      .middle{
        margin: 0px 20px 20px 20px;
        padding-bottom: 80px;
        padding-top: 30px;
        text-align:center;
        border-top: solid 1px $lightgreen;
        @media only screen and (max-width: $breakS) {
          padding-bottom: 100px;
        }
        p{
          line-height: 140%;
          font-size: 20px;
          margin-bottom:10px;
          @media only screen and (max-width: $breakXS) {
            font-size: 15px;
          }
        }
      }
      .bottom{
        border-top: solid 5px $white;
        margin:0;
        padding:0;
        position:absolute;
        width: 100%;
        bottom: 0px;
        a{
          background: $lightgreen;
          display:block;
          color: $white;
          text-transform: uppercase;
          text-align: center;
          text-decoration:none;
          @include sansserif(bold);
          margin:0;
          font-size: 25px;
          padding: 25px 0 20px 0;
          &:hover{
            background: $darkgreen;
          }
          &:active{
            background: $orange;
          }
          @media only screen and (max-width: $breakXS) {
            font-size: 20px;
          }
        }
      }
    }
  }
  .tabs{
    text-align:left;
    margin-top: 20px;
    .tablink{
      cursor: pointer;
      background: $lightgreen;
      padding: 8px 8px 5px 8px;
      border: none;
      @include sansserif(normal);
      color: #fff;
      font-size: 1.5rem;
      &.active{
        background: $darkgreen;
      }
    }  
    .tab{
      background: rgba(255, 255, 255, 0.5);
      border: solid 1px $darkgreen;
      padding: 10px;
      p{font-size: 1.1rem!important;}
      h4{
        margin: 0 0 10px 0;
      }
    } 
  }
}
