@font-face{
  font-family:Minion;
  font-style:normal;
  font-weight:400;
  // src:url(../fonts/2BF373_4_0.eot?#iefix);
  // src:url(../fonts/2BF373_4_0.eot?#iefix) format("embedded-opentype"),url(../fonts/2BF373_4_0.woff) format("woff"),
  src: url(../fonts/2BF373_4_0.ttf) format("truetype");
}
@font-face{
  font-family:Minion;
  font-style:normal;
  font-weight:700;
  // src:url(../fonts/2BF373_14_0.eot?#iefix);src:url(../fonts/2BF373_14_0.eot?#iefix) format("embedded-opentype"),url(../fonts/2BF373_14_0.woff) format("woff"),
  src:url(../fonts/2BF373_14_0.ttf) format("truetype")
}
@font-face{
  font-family:Minion;
  font-style:italic;
  font-weight:400;
  // src:url(../fonts/2BF373_1B_0.eot?#iefix);
  // src:url(../fonts/2BF373_1B_0.eot?#iefix) format("embedded-opentype"),url(../fonts/2BF373_1B_0.woff) format("woff"),
  src: url(../fonts/2BF373_1B_0.ttf) format("truetype");
}
@font-face{
  font-family:SuperGrotesk;
  font-style:normal;
  font-weight:400;
  src:url(../fonts/SuperGroteskPro.eot?#iefix);
  src:url(../fonts/SuperGroteskPro.eot?#iefix) format("embedded-opentype"),url(../fonts/SuperGroteskPro.woff) format("woff"),url(../fonts/SuperGroteskPro.ttf) format("truetype");
}
@font-face{
  font-family:SuperGrotesk;
  font-style:normal;
  font-weight:700;
  src:url(../fonts/SuperGroteskPro-Medium.eot?#iefix);
  src:url(../fonts/SuperGroteskPro-Medium.eot?#iefix) format("embedded-opentype"),url(../fonts/SuperGroteskPro-Medium.woff) format("woff"),url(../fonts/SuperGroteskPro-Medium.ttf) format("truetype");
}

@mixin sansserif($weight: normal) {
  font-family: SuperGrotesk;
  @if $weight == normal {
    font-weight:400;
  }
  @else if $weight == bold {
    font-weight:700;
  }
}

@mixin serif($weight: normal) {
  font-family: Minion;
  @if $weight == normal {
    font-weight:400;
  }
  @else if $weight == bold {
    font-weight:700;
  }
}
